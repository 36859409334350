import * as angular from 'angular';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { ILtiRedirectService } from 'v2/modules/common/lti-redirect-service';
import { IDebugHelper } from 'v2/modules/core/debug-helper';

angular.module('cerego.learnDashboard').controller('learnDashboardLtiRedirectCtrl', [
  '$scope',
  'LtiRedirectService',
  ($scope: v3.controller.ILtiRedirectCtrl, LtiRedirectService: ILtiRedirectService) => {
    $scope.stateUI = {
      error: null
    };

    try {
      LtiRedirectService.exchangeTokenAndRedirect();
    } catch (error) {
      $scope.stateUI.error = error;
    }
  }
]);
