learnDashboardApp.controller('assignmentAdvancedCtrl', ['$scope', '$q', 'DebugHelper', 'AssignmentDetailsService', 'MySeriesResource', 'MySetsResource', 'SiteNavigationService', 'V3MyCoursesResource', 'V3ModelHelper' , ($scope, $q, DebugHelper, AssignmentDetailsService, MySeriesResource, MySetsResource, SiteNavigationService, V3MyCoursesResource, V3ModelHelper) ->
  DebugHelper.register("assignmentAdvancedCtrl", $scope)

  _.assignIn $scope, {
    routes: Packs.iKnowRoutes
    uiState:
      header_string: "js.learn.assignments.advanced"

    addToSchedule: ->
      if $scope.assignment.type == "sets"
        resource = MySetsResource
      else
        resource = MySeriesResource
      resource.addToSchedule
        id: $scope.assignment.id
      , (success) ->
          $scope.assignment.meta['in-schedule'] = true

    removeFromSchedule: ->
      if $scope.assignment.type == "sets"
        resource = MySetsResource
      else
        resource = MySeriesResource
      resource.removeFromSchedule
        id: $scope.assignment.id
      , (success) ->
        $scope.assignment.meta['in-schedule'] = false

    loadCourses: ->
      unless $scope.deferred
        $scope.deferred = $q.defer()
        params = {
          'page[size]': 200
          include: 'image,partner,partner.partner-library'
        }

        if $scope.assignment.type == "sets"
          params['filter[contains_set][]'] = $scope.assignment.id
          $scope.assignment.context_type = "set"
        else
          params['filter[contains_series][]'] = $scope.assignment.id
          $scope.assignment.context_type = "series"


        V3MyCoursesResource.query params
        , (success) ->
          $scope.courses = V3ModelHelper.parse(success)
          $scope.deferred.resolve($scope.courses)
        , $scope.deferred.reject

      $scope.deferred.promise


  }

  AssignmentDetailsService.initialize().then (success) ->
    $scope.assignment = AssignmentDetailsService.assignment
    $scope.memories_count = $scope.assignment.attributes['memories-count'] || 0
    $scope.instructional_items_count = $scope.assignment.attributes['ic-items-count'] || 0

    $scope.loadCourses()
    SiteNavigationService.setContext($scope.assignment, 'assignment')

])
