learnDashboardApp.service('paginatedLearningLibraryService', ['$rootScope', '$q', 'DebugHelper', 'toastHelper', '$filter', '$routeParams', 'UserManager', 'V3CoursesResource', 'V3ModelHelper', ($rootScope, $q, DebugHelper, toastHelper, $filter, $routeParams, UserManager, V3CoursesResource, V3ModelHelper) ->
  $scope = $rootScope.$new()
  DebugHelper.register("paginatedLearningLibraryService", $scope)

  _.assignIn $scope,
    resources: {}
    defaultOptions:
      query: ""
      sort: "name"
      'page[number]': 1
      'page[size]': 15

    getPaginatedResults: (options = {}) ->
      options = _.defaults(options, $scope.defaultOptions)
      options['filter[in_library]'] = true
      options.include = "partner,partner.image"

      deferred = $q.defer()

      UserManager.loadUser().then (user) ->
        V3CoursesResource.query options
        , (success) ->
          $scope.loading = false
          courses = V3ModelHelper.parse(success, includeMeta: true)
          deferred.resolve(courses)
      deferred.promise

  $scope
])
