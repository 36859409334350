learnDashboardApp.controller('courseDetailsCtrl', ['$analytics', '$filter', '$location', '$scope', '$window', 'DebugHelper', 'CourseContextService', 'SiteNavigationService', 'V3CourseUsersResource', 'UserManager', 'toastHelper', 'InstructorNamesHelper', 'paginatedAssignmentsService', 'V3CourseAssignmentsResource', ($analytics, $filter, $location, $scope, $window, DebugHelper, CourseContextService, SiteNavigationService, V3CourseUsersResource, UserManager, toastHelper, InstructorNamesHelper, paginatedAssignmentsService, V3CourseAssignmentsResource) ->
  DebugHelper.register("courseDetailsCtrl", $scope)

  _.assignIn $scope, {
    isV4Embed: SiteNavigationService.isV4Embed()
    uiState:
      header_string: "js.learn.courses.overview"
      isInstructor: false
      tab: 'assignments'
    styles: { showSearchCard: true, assignmentTileable: true }
    forbiddenTemplate: Packs.iKnowRoutes.learn_dashboard_v3_templates_path("payment_required_assignments")
    paginatedAssignmentsService: paginatedAssignmentsService
    routes: Packs.iKnowRoutes
    tabs: [
      slug: "default"
      empty: Packs.iKnowRoutes.learn_dashboard_v3_templates_path("empty_tab_assignments")
    ]

    setInstructorString: ->
      $scope.course.instructor_string = InstructorNamesHelper.getFormattedInstructorNames($scope.course.attributes['instructor-names'])

    unenroll: ->
      confirmationText = if $scope.course.meta["can-unenroll"] then "js.learn.courses.course_detail.unenroll_confirm" else "js.learn.courses.course_detail.unenroll_confirm_extra_careful"
      if $window.confirm($filter('translate')(confirmationText))
        V3CourseUsersResource.delete
          course_id: $scope.course.id
          id: UserManager.currentUser.id
        .$promise.then (success) ->
          $analytics.eventTrack('self_unenrolled_from_course')
          CourseContextService.markRefreshRequired()
          toastHelper.showToast('simpleToast', message: $filter("translate")("js.learn.courses.course_detail.unenroll_toast", name: $scope.course.attributes.name))
          $location.path Packs.iKnowRoutes.v3_dashboard_path()
  }

  CourseContextService.initialize().then (success) ->
    $scope.course = CourseContextService.course
    $scope.partnerSettings = CourseContextService.course.relationships.partner.data.attributes['partner-settings']
    # on payment required, redirect to learn dashboard
    if $scope.course.meta['payment-required']
      $window.location = Packs.iKnowRoutes.v3_dashboard_path()
    $scope.instructional_items_count = $scope.course.attributes['ic-items-count']
    $scope.setInstructorString()
    $scope.uiState.isInstructor = $scope.course.meta['can-edit']
    SiteNavigationService.setContext($scope.course, 'course')
    options = { course_id: $scope.course.id }
    V3CourseAssignmentsResource.get options
    , (success) ->
        $scope.availableAssignmentsCount = success.meta['total-count']
        $scope.availableAssignments = $filter("translate")("js.old.learn.courses.course_detail.available_assignments_count", availableAssignmentsCount: $scope.availableAssignmentsCount)
])
