import assignmentPlaceholder from 'images/v3/assignment_placeholder_60.png'

learnDashboardApp.service('paginatedAssignmentsService', ['$rootScope', '$routeParams', '$q', 'DebugHelper', '$filter', 'V3CourseAssignmentsResource', 'V3ModelHelper', ($rootScope, $routeParams, $q, DebugHelper, $filter, V3CourseAssignmentsResource, V3ModelHelper) ->
  $scope = $rootScope.$new()
  DebugHelper.register("paginatedAssignmentsService", $scope)

  _.assignIn $scope,
    resources: {}
    activeTab: "default"
    defaultOptions:
      sort: ""
      include: 'image'
    defaultResourceImage: assignmentPlaceholder
    lastOptions: {}

    getPaginatedResults: (options = {}) ->
      options = _.defaults(options, $scope.defaultOptions)
      deferred = $q.defer()

      options['course_id'] = $routeParams.id || $routeParams.course_id || options['course_id'] || -1
      options.sort = "position"

      V3CourseAssignmentsResource.get options
      , (success) ->
        $scope.loading = false
        deferred.resolve(V3ModelHelper.parse(success, includeMeta: true))
      , (error) ->
        deferred.reject(error)

      deferred.promise

  $scope
])
