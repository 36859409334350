learnDashboardApp.controller('courseInstructionalItemsCtrl', ['$scope', '$location', 'DebugHelper', 'CourseContextService', 'paginatedInstructionalItemsService', 'SiteNavigationService', ($scope, $location, DebugHelper, CourseContextService, paginatedInstructionalItemsService, SiteNavigationService) ->
  DebugHelper.register("courseInstructionalItemsCtrl", $scope)

  _.assignIn $scope, {
    paginatedInstructionalItemsService: paginatedInstructionalItemsService
    tabs: [
      slug: "default"
      empty: Packs.iKnowRoutes.courses_v3_templates_path("_empty_tab_instructors")
    ]
    isV4Embed: SiteNavigationService.isV4Embed()
    instructionalItemCardPath: Packs.iKnowRoutes.learn_dashboard_v3_templates_path('_instructional_item_tile')
    routes: Packs.iKnowRoutes
    uiState:
      header_string: "js.learn.courses.instructional_items"
      tab: 'instructional_items'
    serviceOptions: {}

    goToItem: (item) ->
      $location.path Packs.iKnowRoutes.v3_learn_course_instructional_item_path($scope.course, item.id)

    initialize: ->
      CourseContextService.initialize().then (success) ->
        $scope.course = CourseContextService.course
        $scope.serviceOptions['course_id'] = CourseContextService.course.id
        if $scope.course.attributes['ic-items-count'] < 1
          $location.path Packs.iKnowRoutes.v3_learn_course_path($scope.course.id)
        SiteNavigationService.setContext($scope.course, 'course')
        $scope.instructional_items_count = $scope.course.attributes['ic-items-count']
  }

  $scope.initialize()
])
