import defaultLearningLibraryImage from 'images/default/big/default-learning-library.png'
import { ANONYMOUS_NAME } from 'javascripts/util/constants'

learnDashboardApp.controller('dashboardCtrl', ['$scope', '$filter', '$routeParams', '$q', 'DebugHelper', 'paginatedMyCoursesService', 'V3AfterStudyMessagesResource', 'UserManager', 'ModalService', 'NpsService', '$window', 'PageTrackingService', ($scope, $filter, $routeParams, $q, DebugHelper, paginatedMyCoursesService, V3AfterStudyMessagesResource, UserManager, ModalService, NpsService, $window, PageTrackingService) ->
  DebugHelper.register("dashboardCtrl", $scope)

  _.assignIn $scope,
    routes: Packs.iKnowRoutes
    uiState: {}
    myCourseTilePath: Packs.iKnowRoutes.common_v3_templates_path('my_course_tile')
    paginatedMyCoursesService: paginatedMyCoursesService
    studyItemModuleNames: []
    styles:
      showSearchInsideList: true
    tabs: [
      slug: "progress"
      name: 'js.learn.dashboard.progress'
      empty: Packs.iKnowRoutes.learn_dashboard_v3_templates_path("_empty_tab_overview_courses")
    ]
    progressOrbs: [
      'js.learn.dashboard.steps_tip.first_learning_session'
      'js.learn.dashboard.steps_tip.start_all_unstarted'
      'js.learn.dashboard.steps_tip.review_fading'
      'js.learn.dashboard.steps_tip.first_mastery'
      'js.learn.dashboard.steps_tip.all_mastery'
    ]
    customOrderings: [
      name: "js.filters.course_progress"
      direction:
        desc: "-course_users.progress"
        asc: "course_users.progress"
      default_direction: "asc"
    ,
      name: "js.filters.name"
      direction:
        desc: "-courses.content_name"
        asc: "courses.content_name"
      default_direction: "asc"
    ,
      name: "js.filters.date"
      direction:
        desc: "-course_users.last_study_time"
        asc: "course_users.last_study_time"
      default_direction: "desc"
    ]

    isOrbActive: (index) ->
      false # the tip card only shows completed orbs, never active

    isOrbComplete: (index) ->
      true #all progress orbs are filled in

    setMobileDownloadTip: () ->
      if /iphone|ipad/i.test(navigator.userAgent)
        $scope.uiState.mobileOS = "ios"
      else if /android/i.test(navigator.userAgent)
        $scope.uiState.mobileOS = "android"

    showPayportal: () ->
      ModalService.showModal('payportal-modal', {
        course: $scope.paginatedMyCoursesService.paymentRequiredCourse,
        user: $scope.currentUser,
        paymentSuccessCallback: () -> $window.location.reload(),
        image: $scope.paginatedMyCoursesService.paymentRequiredCourse.relationships.image.data?.attributes
        excludeController: true
      })

    setTimeOfDay: () ->
      hour = new Date().getHours()
      if 12 > hour >= 0
        $scope.uiState.timeOfDay = "morning"
      else if 18 > hour >= 12
        $scope.uiState.timeOfDay = "afternoon"
      else
        $scope.uiState.timeOfDay = "evening"

    setGreetingString: () ->
      if $scope.currentUser.attributes.name
        firstName = $scope.currentUser.attributes.name.split(" ")[0]
      if firstName && firstName != ANONYMOUS_NAME
        $scope.uiState.greetingString = $filter('translate')('js.learn.dashboard.greeting_messages.with_name.' + $scope.uiState.timeOfDay, name: firstName)
      else
        $scope.uiState.greetingString = $filter('translate')('js.learn.dashboard.greeting_messages.without_name.' + $scope.uiState.timeOfDay)

    setPartnerData: () ->
      partnerLibrary = $scope.currentUser.relationships['primary-partner']['data']?["relationships"]['partner-library']?['data']?['attributes']
      if partnerLibrary
        $scope.uiState.partnerLibraryName = partnerLibrary['name']
        partnerSlug = $scope.currentUser.relationships['primary-partner']['data']['attributes']['slug']
        $scope.uiState.partnerLibraryPath = Packs.iKnowRoutes.v3_partner_learning_library_path(partnerSlug)

    knowledgeBaseClick: () ->
      $window.location.href = Packs.iKnowRoutes.v3_library_path()

    partnerLibraryClick: () ->
      $window.location.href = $scope.uiState.partnerLibraryPath
      
    skipContentPress: ($event) ->
      $event.currentTarget.blur()
      document.getElementById('end-focus').focus()

  $scope.activeTab = $scope.tabs[0]

  UserManager.loadUser().then (currentUser) ->
    if currentUser.id
      $scope.currentUser = currentUser
      $scope.setMobileDownloadTip()
      $scope.setPartnerData()
      $scope.setTimeOfDay()
      $scope.setGreetingString()
      NpsService.displayNPS(currentUser)
      PageTrackingService.trackPage('dashboard')
    else
      cerego_www = SERVER_URLS['www']
      $window.location.href = if cerego_www then cerego_www else Packs.iKnowRoutes.v3_signin_path()
])
