learnDashboardApp.service('instructionalItemShowService', ['$rootScope', '$routeParams', '$q', 'DebugHelper', '$filter', 'V3ItemsResource', 'V3ModelHelper', 'uuid', ($rootScope, $routeParams, $q, DebugHelper, $filter, V3ItemsResource, V3ModelHelper, uuid) ->
  $scope = $rootScope.$new()
  DebugHelper.register("instructionalItemShowService", $scope)

  _.assignIn $scope,
    timeForItems: {}
    deferredForItems: {}
    studySessionId: uuid.v4()

    loadItem: (id) ->
      unless $scope.deferredForItems[id]
        $scope.deferredForItems[id] = $q.defer()
        V3ItemsResource.get
          id: $routeParams.id
          include: "annotations,annotations.document,annotations.image,annotations.sound,annotations.video"
        , (success) ->
          item = V3ModelHelper.parse(success)
          if item.attributes['template-type'] == "instructional_contents"
            $scope.currentItem = item
            $scope.timeForItems[id] ||= 0
            $scope.deferredForItems[id].resolve(item)
          else
            $scope.deferredForItems[id].reject
        , (failure) ->
          $scope.deferredForItems[id].reject

      $scope.deferredForItems[id].promise

  $scope
])
