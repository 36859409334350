learnDashboardApp.service('paginatedMyCollectionsService', ['$rootScope', '$q', 'DebugHelper', 'toastHelper', '$filter', 'UserManager', 'V3MyCollectionsResource', 'V3ModelHelper', ($rootScope, $q, DebugHelper, toastHelper, $filter, UserManager, V3MyCollectionsResource, V3ModelHelper) ->
  $scope = $rootScope.$new()
  DebugHelper.register("paginatedMyCollectionsService", $scope)

  _.assignIn $scope,
    resources: {}
    activeTab: "default"
    defaultOptions:
      include: 'image'
      filter: {}
    defaultResourceImage: '<%= image_path("v3/assignment_placeholder_60.png") %>'
    lastOptions: {}

    getPaginatedResults: (options = {}) -> # constraints param will need to take care of tabs #---
# !!! need to merge options in case they only provide a page number but otherwise rely on defaults !!!
      options = _.defaults(options, $scope.defaultOptions)
      tab = options.tab
      options.filter = {}
      deferred = $q.defer()

      switch tab
        when "goal_in_progress"
          options.filter.goal_in_progress = true
        when "goal_reached"
          options.filter.goal_reached = true
        when "optional"
          options.filter.optional = true
        when "has_studied"
          options.filter.has_studied = true
        when "created"
          options.filter.created = true
        else
          options.filter.ready = true

      UserManager.loadUser(true).then (user) ->
        V3MyCollectionsResource.query options
        , (success) ->
          $scope.loading = false
          deferred.resolve(V3ModelHelper.parse(success, includeMeta: true))
      deferred.promise

  $scope
])
