import * as angular from 'angular';
import { IDebugHelper } from 'v2/modules/core/debug-helper';

interface IFrameMessage {
  messageType: string;
  context: {};
  data: {};
}

export interface IFrameMessageService extends ng.IScope {
  postMessage: (IFrameMessage) => void;
  target: ng.IWindowService;
}

angular.module('cerego.common').service('iFrameMessageService', [
  '$window',
  '$rootScope',
  'DebugHelper',
  ($window: ng.IWindowService, $rootScope: ng.IRootScopeService, DebugHelper: IDebugHelper) => {
    const $scope = $rootScope.$new() as IFrameMessageService;

    DebugHelper.register('iFrameMessageService', $scope);

    $scope.postMessage = message => {
      $scope.target.postMessage(message, '*');
    };

    // on non-iframed windows, the parent is equal to the window
    if ($window.parent === $window && $window.opener) {
      $scope.target = $window.opener;
    } else {
      $scope.target = $window.parent;
    }

    return $scope;
  }
]);
