learnDashboardApp.controller('courseAssignmentsCtrl', ['$scope', 'DebugHelper', 'CourseContextService', 'paginatedAssignmentsService', 'SiteNavigationService', ($scope, DebugHelper, CourseContextService, paginatedAssignmentsService, SiteNavigationService) ->
  DebugHelper.register("courseAssignmentsCtrl", $scope)

  _.assignIn $scope, {
    styles: { showSearchCard: true, assignmentTileable: true }

    customOrderings: [
      name: "js.filters.order"
      direction:
        desc: "position"
        asc: "-position"
      default_direction: "desc"
      draggable: true
    ,
      name: "js.filters.name"
      direction:
        desc: "name"
        asc: "-name"
      default_direction: "desc"
    ]

    forbiddenTemplate: Packs.iKnowRoutes.learn_dashboard_v3_templates_path("payment_required_assignments")
    paginatedAssignmentsService: paginatedAssignmentsService
    tabs: [
      slug: "default"
      empty: Packs.iKnowRoutes.learn_dashboard_v3_templates_path("empty_tab_assignments")
    ]
    instructionalItemCardPath: Packs.iKnowRoutes.learn_dashboard_v3_templates_path('_instructional_item_tile')
    uiState:
      header_string: "js.learn.courses.assignments"

    initialize: ->
      CourseContextService.initialize().then (success) ->
        $scope.course = CourseContextService.course
        SiteNavigationService.setContext($scope.course, 'course')
  }

  $scope.initialize()
])
