learnDashboardApp.controller('learningLibraryCtrl', ['$scope', '$filter', '$routeParams', 'DebugHelper', 'paginatedMyCollectionsService', 'paginatedMyCoursesService', 'UserManager', 'paginatedLearningLibraryService', 'PartnerLibraryResource', 'V3ModelHelper', ($scope, $filter, $routeParams, DebugHelper, paginatedMyCollectionsService, paginatedMyCoursesService, UserManager, paginatedLearningLibraryService, PartnerLibraryResource, V3ModelHelper) ->
  DebugHelper.register("learningLibraryCtrl", $scope)

  _.assignIn $scope,
    paginatedLearningLibraryService: paginatedLearningLibraryService
    courseCardPath: Packs.iKnowRoutes.common_v3_templates_path('course_card')

    uiState: {
      emptyLibrary: false
    }

    serviceOptions: {}

    tabs: [
      slug: "default"
      empty: Packs.iKnowRoutes.learn_dashboard_v3_templates_path("empty_learning_library")
    ]

    styles: {
      showSearchCard: true
      courseTileable: true
    }

    customOrderings: [
      name: "js.filters.title"
      direction:
        desc: "name"
        asc: "-name"
      default_direction: "desc"
    ]

    PartnerLibraryResource.get
      partner_id: $routeParams.partner_id
      include: "image"
    , (success) ->
      $scope.library = V3ModelHelper.parse(success)
      $scope.serviceOptions['filter[partner_id]'] = $scope.library.relationships.partner.data.id
    , (error) ->
      DebugHelper.logError(error, 'Error getting PartnerLibraryResource')

])
