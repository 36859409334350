learnDashboardApp.controller("assignmentMemoriesCtrl", ['$scope', 'DebugHelper', 'AssignmentDetailsService', 'SiteNavigationService', 'V3SetItemsResource', 'V3SeriesItemsResource', 'V3ModelHelper', 'PassageHighlightingService', ($scope, DebugHelper, AssignmentDetailsService, SiteNavigationService, V3SetItemsResource, V3SeriesItemsResource, V3ModelHelper, PassageHighlightingService) ->
  DebugHelper.register("assignmentMemoriesCtrl", $scope)

  _.assignIn $scope,
    routes: Packs.iKnowRoutes
    modulesTilesPath: Packs.iKnowRoutes.courses_v3_templates_path('module_tile')
    set_ids: []
    searchOptions:
      query: ""
    uiState:
      header_string: "js.learn.assignments.concepts"

    loadItems: () ->
      params = {
        include:  "image,facets.anchor.sound,facets.association.sound,facets.anchor.image,facets.association.image"
        'filter[type][]': ["AssociationCollection", "Vocabulary", "Pattern", "ApplicationQuestion", "QuestionAndAnswer", "ImageMap", "ClozeContainer", "Sequence"]
        'page[size]': 200
      }
      if $scope.assignment.context_type == "set"
        resource = V3SetItemsResource
        params["set_id"] = $scope.assignment.id
      else
        resource = V3SeriesItemsResource
        params["series_id"] = $scope.assignment.id


      resource.query params
      , (success) ->
        $scope.items = V3ModelHelper.parse(success)
        $scope.createHighlightedPassages()

    createHighlightedPassages: ->
      for item in $scope.items
        if item.attributes['template-type'] == "passages"
          clozes = $scope.getClozes(item)
          item.highlightedPassage = PassageHighlightingService.passageWithHighlights(clozes, item.attributes.text)

    getClozes: (item) ->
      clozes = []
      for cloze in _.filter(item.relationships?.facets?.data, (aCloze) -> aCloze.relationships?.anchor?.data?.type == "passage-nodes")
        clozeObject = cloze.relationships.anchor.data.attributes;
        clozeObject.cloze_text = cloze.relationships.anchor.data.attributes['cloze-text'];
        clozes.push(clozeObject)
      clozes

  AssignmentDetailsService.initialize().then (success) ->
    $scope.assignment = AssignmentDetailsService.assignment
    if $scope.assignment.type == "sets"
      $scope.assignment.context_type = "set"
      $scope.set_ids << $scope.assignment.id
    else
      $scope.assignment.context_type = "series"
      _.each $scope.assignment.relationships.sets.data, (set) ->
        $scope.set_ids << set.id

    $scope.memories_count = $scope.assignment.attributes['memories-count'] || 0
    $scope.instructional_items_count = $scope.assignment.attributes['ic-items-count'] || 0

    $scope.loadItems()
    SiteNavigationService.setContext($scope.assignment, 'assignment')

  $scope.debouncedSearchItems = _.debounce($scope.loadItems, 200)
])
