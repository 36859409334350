# This app contains most of the V3 pages that live in the Green Learn product, but outside of the actual learn app itself

require('../../../vendor/assets/javascripts/v2/jquery-ui-1.10.4.custom')
require('../../../lib/assets/javascripts/cerego_sdk/shared_lib')

# require this via webpack later
# "v2/modules/cerego-layout-app" remove from v3/_head
# "v3/modules/common"

window.learnDashboardApp = angular.module("cerego.learnDashboard", [
  "ngPromiseExtras"
  "angular-uuid"
  "cerego.layout"
]).config(['$routeProvider', ($routeProvider) ->
  $routeProvider
  .when Packs.iKnowRoutes.v3_dashboard_path(),
    templateUrl : Packs.iKnowRoutes.learn_dashboard_v3_templates_path("dashboard")
    controller   : 'dashboardCtrl'
    resolve:
      $titleTag:  -> ("js.title_tags.dashboard")
      $product:   -> 'learn'
  .when Packs.iKnowRoutes.v3_learn_course_path(":id"),
    templateUrl : Packs.iKnowRoutes.courses_v3_learn_dashboard_templates_path("show")
    controller   : 'courseDetailsCtrl'
    resolve:
      $titleTag: -> ("js.title_tags.overview")
      $product: -> 'learn'
      $tab: -> 'overview'
  .when Packs.iKnowRoutes.v3_learn_course_instructional_items_path(":id"),
    templateUrl : Packs.iKnowRoutes.courses_v3_learn_dashboard_templates_path("instructional_items")
    controller   : 'courseInstructionalItemsCtrl'
    resolve:
      $titleTag: -> ("js.title_tags.instructional_items")
      $product: -> 'learn'
      $tab: -> 'instructional_items'
  .when Packs.iKnowRoutes.v3_learn_course_instructional_item_path(":course_id", ":id"),
    templateUrl : Packs.iKnowRoutes.learn_dashboard_v3_templates_path("instructional_item")
    controller  : 'instructionalItemShowCtrl'
    resolve:
      $titleTag:  -> "js.title_tags.instructional_items"
      $product:   -> 'learn'
      $tab:       -> 'instructional_items'
  .when Packs.iKnowRoutes.v3_learn_set_path(":id"),
    templateUrl : Packs.iKnowRoutes.assignments_v3_learn_dashboard_templates_path("show")
    controller  : 'assignmentDetailsCtrl'
    resolve:
      $titleTag:  -> "js.title_tags.assignments"
      $product:   -> 'learn'
      $tab:       -> 'overview'
      $assignment_type:   -> 'set'
  .when Packs.iKnowRoutes.v3_learn_series_path(":id"),
    templateUrl : Packs.iKnowRoutes.assignments_v3_learn_dashboard_templates_path("show")
    controller  : 'assignmentDetailsCtrl'
    resolve:
      $titleTag:  -> "js.title_tags.assignments"
      $product:   -> 'learn'
      $tab:       -> 'overview'
      $assignment_type:   -> 'series'
  .when Packs.iKnowRoutes.v3_learn_set_instructional_items_path(":id"),
    templateUrl : Packs.iKnowRoutes.assignments_v3_learn_dashboard_templates_path("instructional_items")
    controller  : 'assignmentInstructionalItemsCtrl'
    resolve:
      $titleTag:  -> "js.title_tags.assignments"
      $product:   -> 'learn'
      $tab:       -> 'instructional_items'
      $assignment_type:   -> 'set'
  .when Packs.iKnowRoutes.v3_learn_set_instructional_item_path(":set_id", ":id"),
    templateUrl : Packs.iKnowRoutes.learn_dashboard_v3_templates_path("instructional_item")
    controller  : 'instructionalItemShowCtrl'
    resolve:
      $titleTag:  -> "js.title_tags.assignments"
      $product:   -> 'learn'
      $tab:       -> 'instructional_items'
      $assignment_type:   -> 'set'
  .when Packs.iKnowRoutes.v3_learn_set_memories_path(":id"),
    templateUrl : Packs.iKnowRoutes.assignments_v3_learn_dashboard_templates_path("memories")
    controller  : 'assignmentMemoriesCtrl'
    resolve:
      $titleTag:  -> "js.title_tags.assignments"
      $product:   -> 'learn'
      $tab:       -> 'memories'
      $assignment_type:   -> 'set'
  .when Packs.iKnowRoutes.v3_learn_series_instructional_items_path(":id"),
    templateUrl : Packs.iKnowRoutes.assignments_v3_learn_dashboard_templates_path("instructional_items")
    controller  : 'assignmentInstructionalItemsCtrl'
    resolve:
      $titleTag:  -> "js.title_tags.assignments"
      $product:   -> 'learn'
      $tab:       -> 'instructional_items'
      $assignment_type:   -> 'series'
  .when Packs.iKnowRoutes.v3_learn_series_instructional_item_path(":series_id", ":id"),
    templateUrl : Packs.iKnowRoutes.learn_dashboard_v3_templates_path("instructional_item")
    controller  : 'instructionalItemShowCtrl'
    resolve:
      $titleTag:  -> "js.title_tags.assignments"
      $product:   -> 'learn'
      $tab:       -> 'instructional_items'
      $assignment_type:   -> 'series'
  .when Packs.iKnowRoutes.v3_learn_series_memories_path(":id"),
    templateUrl : Packs.iKnowRoutes.assignments_v3_learn_dashboard_templates_path("memories")
    controller  : 'assignmentMemoriesCtrl'
    resolve:
      $titleTag:  -> "js.title_tags.assignments"
      $product:   -> 'learn'
      $tab:       -> 'memories'
      $assignment_type:   -> 'series'
  .when Packs.iKnowRoutes.v3_learn_series_assignments_path(":id"),
    templateUrl : Packs.iKnowRoutes.assignments_v3_learn_dashboard_templates_path("assignments")
    controller  : 'assignmentAssignmentsCtrl'
    resolve:
      $titleTag:  -> "js.title_tags.assignments"
      $product:   -> 'learn'
      $tab:       -> 'assignments'
      $assignment_type:   -> 'series'
  .when Packs.iKnowRoutes.v3_learn_set_advanced_index_path(":id"),
    templateUrl : Packs.iKnowRoutes.assignments_v3_learn_dashboard_templates_path("advanced")
    controller  : 'assignmentAdvancedCtrl'
    resolve:
      $titleTag: -> "js.title_tags.assignments"
      $product: -> 'learn'
      $tab:       -> 'advanced'
      $assignment_type:  -> 'set'
  .when Packs.iKnowRoutes.v3_learn_series_advanced_index_path(":id"),
    templateUrl : Packs.iKnowRoutes.assignments_v3_learn_dashboard_templates_path("advanced")
    controller  : 'assignmentAdvancedCtrl'
    resolve:
      $titleTag: -> "js.title_tags.assignments"
      $product: -> 'learn'
      $tab:       -> 'advanced'
      $assignment_type:  -> 'series'
  .when Packs.iKnowRoutes.v3_learn_instructional_item_path(":id"),
    templateUrl : Packs.iKnowRoutes.learn_dashboard_v3_templates_path("instructional_item")
    controller  : 'instructionalItemShowCtrl'
    resolve:
      $titleTag:  -> "js.title_tags.instructional_items"
      $product:   -> 'learn'
      $tab:       -> 'overview'
  .when Packs.iKnowRoutes.v3_library_path(),
    templateUrl : Packs.iKnowRoutes.learn_dashboard_v3_templates_path("library")
    controller  : 'libraryCtrl'
    resolve:
      $titleTag: -> "js.title_tags.knowledge_bank"
      $product: -> 'learn'
  .when Packs.iKnowRoutes.v3_partner_learning_library_path(":partner_id"),
    templateUrl : Packs.iKnowRoutes.learn_dashboard_v3_templates_path("learning_library")
    controller  : 'learningLibraryCtrl'
    resolve:
      $titleTag: -> "js.title_tags.library"
      $product: -> 'learn'
  .when Packs.iKnowRoutes.v3_lti_redirect_learn_dashboard_path(),
    templateUrl : Packs.iKnowRoutes.common_v3_templates_path("lti_redirect")
    controller  : 'learnDashboardLtiRedirectCtrl'
  .otherwise
      redirectTo: Packs.iKnowRoutes.v3_dashboard_path()
])

req = require.context('../../assets/javascripts/v3/modules/learn-dashboard/resources', false, /\.js$/)
req.keys().forEach(req)

require('../../assets/javascripts/v3/modules/learn-dashboard/instructional-item-show-service.js')
require('../../assets/javascripts/v3/modules/learn-dashboard/paginated-assignments-service.js')
require('../../assets/javascripts/v3/modules/learn-dashboard/paginated-my-collections-service.js')
require('../../assets/javascripts/v3/modules/learn-dashboard/paginated-my-courses-service.js')
require('../../assets/javascripts/v3/modules/learn-dashboard/paginated-learning-library-courses-service.js')
require('../../assets/javascripts/v3/modules/common/services/iframe-message-service.ts')


req = require.context('../../assets/javascripts/v3/modules/learn-dashboard/controllers', false)
req.keys().forEach(req)
