learnDashboardApp.controller('instructionalItemShowCtrl', ['$scope', '$location', '$q', '$routeParams', 'DebugHelper', 'AssignmentDetailsService', 'CourseContextService', 'instructionalItemShowService', 'SiteNavigationService', 'V3InstructionalPresentationsResource', 'V3ItemsResource', 'V3ModelHelper', 'V3SetItemsResource', ($scope, $location, $q, $routeParams, DebugHelper, AssignmentDetailsService, CourseContextService, instructionalItemShowService, SiteNavigationService, V3InstructionalPresentationsResource, V3ItemsResource, V3ModelHelper, V3SetItemsResource) ->
  DebugHelper.register("instructionalItemShowCtrl", $scope)

  _.assignIn $scope, {
    uiState: {}

    getCurrentTime: () ->
      now = new Date()
      now.toISOString()

    loadItem: () ->
      item_id = $routeParams.id
      instructionalItemShowService.loadItem(item_id).then (item) ->
        $scope.startTime = Date.now()
        $scope.item = item
        $scope.setUIState()
        $scope.setSiteNav()

    setUIState: ->
      pdfAnnotation = _.find($scope.item.relationships?.annotations.data, (annotation) ->
        annotation.relationships.document.data?.attributes['content-type'] == "application/pdf"
      )

      embedItemType = $scope.item.attributes["ic-subtype"] == "instructional_embed"

      if pdfAnnotation || embedItemType
        $scope.uiState.fullInstructionalEmbed = true

      if $scope.item?.meta['already-seen']
        $scope.uiState.alreadySeen = true

    markAsDone: () ->
      $scope.updateStudyDuration()

      params = {}
      params.study_time_millis = instructionalItemShowService.timeForItems[$scope.item.id]
      params.session_guid = instructionalItemShowService.studySessionId
      params.item_id = $scope.item.id
      params.occurred_at = $scope.getCurrentTime()


      V3InstructionalPresentationsResource.create params
      , (success) ->
        $scope.item.meta ||= {}
        $scope.item.meta['already-seen'] = true
        $scope.uiState.alreadySeen = true

    setSiteNav: () ->
      if $scope.assignment
        SiteNavigationService.setContext($scope.assignment, 'assignment', 'instructional_content')
      else if $scope.course
        SiteNavigationService.setContext($scope.course, 'course', 'instructional_content')
      else
        SiteNavigationService.setContext(null, null, 'instructional_content')

    updateStudyDuration: () ->
      $scope.endTime = Date.now()
      sessionLength = $scope.endTime - $scope.startTime
      instructionalItemShowService.timeForItems[$scope.item.id] += sessionLength
  }


  if $routeParams.course_id
    CourseContextService.initialize().then (success) ->
      $scope.course = CourseContextService.course
      $scope.loadItem()
    , (failure) ->
      $location.path Packs.iKnowRoutes.v3_learn_instructional_item_path($routeParams.id)
  else if ($routeParams.set_id || $routeParams.series_id)
    AssignmentDetailsService.initialize().then (success) ->
      $scope.assignment = AssignmentDetailsService.assignment
      $scope.loadItem()
    , (failure) ->
      $location.path Packs.iKnowRoutes.v3_learn_instructional_item_path($routeParams.id)
  else
    $scope.loadItem()
    SiteNavigationService.setContext(null, null, 'instructional_content')

  $scope.$on "$routeChangeStart", ->
    $scope.updateStudyDuration()
#
#
])
