learnDashboardApp.controller('libraryCtrl', ['$scope', '$filter', 'DebugHelper', 'paginatedMyCollectionsService', 'paginatedMyCoursesService', 'UserManager', ($scope, $filter, DebugHelper, paginatedMyCollectionsService, paginatedMyCoursesService, UserManager) ->
  DebugHelper.register("libraryCtrl", $scope)

  _.assignIn $scope,
    assignmentCardPath: Packs.iKnowRoutes.common_v3_templates_path('assignment_card')
    paginatedMyCollectionsService: paginatedMyCollectionsService

    uiState: {
      emptyLibrary: false
    }

    customOrderings: [
      name: "js.filters.title"
      direction:
        desc: "name"
        asc: "-name"
      default_direction: "desc"
    ]

    styles: {
      showSearchCard: true
      assignmentTileable: true
    }
    tabs: [
      slug: "goal_in_progress"
      name: "js.learn.assignments.goal_in_progress"
      empty: Packs.iKnowRoutes.learn_dashboard_v3_templates_path("empty_tab_goal_in_progress")
      isEmpty: false
      defaultHide: true
    ,
      slug: "goal_reached"
      name: "js.learn.assignments.goal_reached"
      empty: Packs.iKnowRoutes.learn_dashboard_v3_templates_path("empty_tab_goal_reached")
      isEmpty: false
      defaultHide: true
    ,
#      slug: "optional"
#      name: "js.learn.assignments.optional"
#      empty: Packs.iKnowRoutes.learn_dashboard_v3_templates_path("empty_tab_optional")
#      isEmpty: false
#      defaultHide: true
#    ,
      slug: "created"
      name: "js.learn.assignments.created_by_me"
      empty: Packs.iKnowRoutes.learn_dashboard_v3_templates_path("empty_tab_created_by_me")
      isEmpty: false
      defaultHide: false
    ,
      slug: "has_studied"
      name: "js.learn.assignments.has_studied"
      empty: Packs.iKnowRoutes.learn_dashboard_v3_templates_path("empty_tab_everything_studied")
      isEmpty: false
      defaultHide: false
    ]

    formatLibraryTabs: (searchQuery) ->
      for tab in $scope.tabs
        if !tab.isEmpty || !tab.defaultHide # If all tabs have been loaded go through tabs until you find one able to be set as the default tab
          $scope.activeTab = tab
          break
      if searchQuery.length == 0 && _.find($scope.tabs, slug: "goal_in_progress").isEmpty && _.find($scope.tabs, slug: "goal_reached").isEmpty
        _.find($scope.tabs, slug: "optional").name = 'js.learn.assignments.in_schedule'
      $scope.activeTab

  UserManager.loadUser(true).then (currentUser) ->
    $scope.currentUser = currentUser
    $scope.uiState.emptyLibrary = (currentUser.attributes['courses-count'] == 0 && currentUser.attributes['sets-count'] == 0)

])
