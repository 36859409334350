learnDashboardApp.controller('assignmentDetailsCtrl', ['$scope', '$q', '$filter', '$routeParams', '$location', '$timeout', 'DebugHelper', 'AssignmentDetailsService', 'GoalMathHelper', 'SiteNavigationService', 'CourseContextService', ($scope, $q, $filter, $routeParams, $location, $timeout, DebugHelper, AssignmentDetailsService, GoalMathHelper, SiteNavigationService, CourseContextService) ->
  DebugHelper.register("assignmentDetailsCtrl", $scope)

  _.assignIn $scope, {
      uiState:
        header_string: "js.learn.assignments.overview"
  }

  AssignmentDetailsService.initialize().then (success) ->
    $scope.assignment = AssignmentDetailsService.assignment
    if $scope.assignment.type == "sets"
      $scope.assignment.context_type = "set"
    else
      $scope.assignment.context_type = "series"
    $scope.memories_count = $scope.assignment.attributes['studiable-memories-count'] || 0
    $scope.uiState.isScorm = !!$scope.assignment.attributes['scorm-package-id']
    if $scope.uiState.isScorm && $scope.assignment.meta['scorm-progress'] && $scope.assignment.meta['scorm-progress'] == 1
      $scope.uiState.scormComplete = true
    $scope.instructional_items_count = $scope.assignment.attributes['ic-items-count'] || 0
    if $scope.assignment.meta['due-at']
      $scope.due_date = $scope.assignment.meta['due-at']
      $scope.pastDue = (new Date() > new Date($scope.assignment.meta['due-at']))
      $scope.percent_progress = parseInt($scope.assignment.meta['memory-aggregate'].progress * 100) || 0
      localizedDateString = $filter("localizeDateFormat")($scope.due_date)
      $scope.toolTipOptionalVars = { localizedDateString: localizedDateString }

    SiteNavigationService.setContext($scope.assignment, 'assignment')
])
