learnDashboardApp.service('paginatedMyCoursesService', ['$rootScope', '$q', 'DebugHelper', 'toastHelper', '$filter', 'UserManager', 'V3CoursesResource', 'V3MyCoursesResource', 'V3ModelHelper', ($rootScope, $q, DebugHelper, toastHelper, $filter, UserManager, V3CoursesResource, V3MyCoursesResource, V3ModelHelper) ->
  $scope = $rootScope.$new()
  DebugHelper.register("paginatedMyCoursesService", $scope)

  _.assignIn $scope,
    resources: {}
    activeTab: "default"
    defaultOptions:
      sort: "-course_users.last_study_time"
      include: 'image,partner,partner.partner-library'
      filter: { ready: true }
    defaultResourceImage: '<%= image_path("v3/assignment_placeholder_60.png") %>'
    lastOptions: {}

    loadPaymentRequiredCourse: (courseId) ->
      V3CoursesResource.get id: courseId
      , (success) ->
        $scope.paymentRequiredCourse = V3ModelHelper.parse(success)

    getPaginatedResults: (options = {}) -> # constraints param will need to take care of tabs #---
      options = _.defaults(options, $scope.defaultOptions)
      deferred = $q.defer()

      UserManager.loadUser(true).then (user) ->
        V3MyCoursesResource.query options
        , (success) ->
          $scope.loading = false
          myCourses = V3ModelHelper.parse(success, includeMeta: true)
          if myCourses.meta['payment-required-course-ids']?.length > 0
            $scope.loadPaymentRequiredCourse(myCourses.meta['payment-required-course-ids'][0])
          deferred.resolve(myCourses)
      deferred.promise

  $scope
])
