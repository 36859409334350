learnDashboardApp.controller('assignmentInstructionalItemsCtrl', ['$scope', '$location', '$q', 'DebugHelper', 'AssignmentDetailsService', 'paginatedInstructionalItemsService',  'SiteNavigationService', 'V3ModelHelper', 'V3SetItemsResource', 'V3SeriesItemsResource', ($scope, $location, $q, DebugHelper, AssignmentDetailsService, paginatedInstructionalItemsService, SiteNavigationService, V3ModelHelper, V3SetItemsResource, V3SeriesItemsResource) ->
  DebugHelper.register("assignmentInstructionalItemsCtrl", $scope)

  _.assignIn $scope, {
    time: Date.now()

    loadItems: ->
      options = {}

      if $scope.assignment.type == "sets"
        resource = V3SetItemsResource
        options['set_id'] = $scope.assignment.id
        redirect_path = Packs.iKnowRoutes.v3_learn_set_path($scope.assignment.id)
      else
        resource = V3SeriesItemsResource
        options['series_id'] = $scope.assignment.id
        redirect_path = Packs.iKnowRoutes.v3_learn_series_path($scope.assignment.id)


      paginatedInstructionalItemsService.getPaginatedResults(options).then (resources) ->
        if resources.data.length < 1
          $location.path redirect_path
        else
          $scope.instructional_items = V3ModelHelper.parse(resources)
          _.each($scope.instructional_items, (item) ->
            if $scope.assignment.type == "series"
              item.show_path = Packs.iKnowRoutes.v3_learn_series_instructional_item_path($scope.assignment, item.id)
            else
              item.show_path = Packs.iKnowRoutes.v3_learn_set_instructional_item_path($scope.assignment, item.id)
          )
  }

  AssignmentDetailsService.initialize().then (success) ->
    $scope.assignment = AssignmentDetailsService.assignment
    $scope.memories_count = $scope.assignment.attributes['memories-count'] || 0
    $scope.instructional_items_count = $scope.assignment.attributes['ic-items-count'] || 0
    $scope.loadItems()
    SiteNavigationService.setContext($scope.assignment, 'assignment')

])
