learnDashboardApp.controller("assignmentAssignmentsCtrl", ['$scope', '$q', 'DebugHelper', 'AssignmentDetailsService', 'SiteNavigationService', 'UserManager', 'V3SetsResource', 'V3SetItemsResource', 'V3ModelHelper', ($scope, $q, DebugHelper, AssignmentDetailsService, SiteNavigationService, UserManager, V3SetsResource, V3SetItemsResource, V3ModelHelper) ->
  DebugHelper.register("assignmentAssignmentsCtrl", $scope)

  _.assignIn $scope,
    set_ids: []
    sets: []
    deferredForSet: {}
    searchOptions:
      query: ""
    uiState:
      header_string: "js.learn.assignments.assignments"

    loadSets: () ->
      _.each($scope.set_ids, (set_id) ->
        unless $scope.deferredForSet[set_id]
          $scope.deferredForSet[set_id] = $q.defer()
          V3SetsResource.get
            id: set_id
            include: "image"
          , (success) ->
            set = V3ModelHelper.parse(success)
            $scope.sets.push(set)
            $scope.deferredForSet[set_id].resolve(set)
          , $scope.deferredForSet[set_id].reject

        $scope.deferredForSet[set_id]
      )

  AssignmentDetailsService.initialize().then (success) ->
    $scope.assignment = AssignmentDetailsService.assignment
    $scope.assignment.context_type = "series"
    _.each($scope.assignment.relationships.sets.data, (set) ->
      $scope.set_ids.push(set.id)
    )

    $scope.instructional_items_count = $scope.assignment.attributes['ic-count'] || 0
    $scope.assignments_count = $scope.set_ids.length

    $scope.loadSets()
    SiteNavigationService.setContext($scope.assignment, 'assignment')

])
